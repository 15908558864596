<template>
  <div>
  
  </div>
</template>

<script>
export default {
  name: 'isLogin',
  components: {
    
  },
  created(){
      this.getLogin()
  },
  methods:{
      getLogin(){
          this.$http.post(this.GLOBAL.new_url+'/user/service/getUserByPhone',{
              userMobile:this.$route.query.userMobile
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                  console.log(res)
              if(res.data.code == "200"){
                  this.$cookie.setCookie("username", res.data.data.userMobile, 7);
              this.$cookie.setCookie("u_id", res.data.data.userCode, 7);
              // 1非代理 2代理 3顾问
              if (res.data.data.isAdvise == "1") {
                this.$cookie.setCookie("user_type", '3', 7);
              } else {
                this.$cookie.setCookie("user_type", res.data.data.userType, 7);
              }
              this.$cookie.setCookie("consultant", res.data.data.empCode, 7);
               this.$cookie.setCookie("angentType", res.data.data.angentType, 7);
               this.$http.post(this.GLOBAL.new_url+'/user/service/newPutUserLogin',{
                userMobile:this.$route.query.userMobile,
                loginWay:5
                },{
                        headers:{
                            AuthToken:this.$cookie.getCookie('token'),
                            'X-Request-Origin':'xcx'
                        }
                                }).then(res=>{
                                    if(res.data.code == '200'){
                                        this.$cookie.setCookie("token", res.data.data.userToken, 7);
                                        window.localStorage.setItem('UserAgent','HT')
                                                if(this.$route.query.type == '提交资料'){
                                                    this.toSummit()
                                                }else if(this.$route.query.type == '修改资料'){
                                                    this.updateSum()
                                                }else if(this.$route.query.type == '立即支付'){
                                                     this.$router.push({
                                                        path:'/checkOrder',
                                                        query:{
                                                        order:this.$route.query.orderCode
                                                        }
                                                    })
                                                }else if(this.$route.query.type == '查看详情'){
                                                    if(this.$route.query.firstType == "1"){
                                                        this.$router.push({
                                                            path:'/personal/orderDetail',
                                                            query:{
                                                                id:this.$route.query.orderCode
                                                            }
                                                            })
                                                    }else if(this.$route.query.firstType == "2"){
                                                        this.$router.push({
                                                                path:'/personal/patentDetailed',
                                                                query:{
                                                                    id:this.$route.query.orderCode
                                                                }
                                                                })
                                                    }else{
                                                        this.$router.push({
                                                                path:'/personal/copyrightDetailed',
                                                                query:{
                                                                    id:this.$route.query.orderCode
                                                                }
                                                                })
                                                    }
                                                     
                                                }else{
                                                    if(this.$route.query.firstType == "1"){
                                                        this.$router.push({
                                                        path:'/tradeDetail',
                                                        query:{
                                                            parentName:this.$route.query.goodsType,
                                                            secondName:this.$route.query.goodsName,
                                                            }
                                                        });
                                                    }else if(this.$route.query.firstType == "2"){
                                                        this.$router.push({
                                                        path:'/patentDetail',
                                                        query:{
                                                            parentName:this.$route.query.goodsType,
                                                            secondName:this.$route.query.goodsName,
                                                            }
                                                        });
                                                    }else{
                                                        this.$router.push({
                                                        path:'/copyrightDetail',
                                                        query:{
                                                            parentName:this.$route.query.goodsType,
                                                            secondName:this.$route.query.goodsName,
                                                            }
                                                        });
                                                    }
                                                    
                                                }
                                    }
                                })
              
              
              }
            })
      },
     toSummit(){
          if(this.$route.query.goodsName == "AI商标注册"){
                                this.$router.push({
                                    path:'/registerData',
                                    query:{
                                        click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                    }
                                    });
                            }else if(this.$route.query.goodsName == "商标变更"){
                              this.$router.push({
                                  path:'/changeData',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标续展"||this.$route.query.goodsName == "商标宽展"){
                              this.$router.push({
                                  path:'/extension',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标转让"){
                              this.$router.push({
                                  path:'/transferData',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标许可备案"){
                              this.$router.push({
                                  path:'/filingData',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "补发商标证书"){
                              this.$router.push({
                                  path:'/resendData',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标驳回复审"){
                              this.$router.push({
                                  path:'/recheck',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标异议申请"){
                              this.$router.push({
                                  path:'/objectData',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标异议答辩"){
                              this.$router.push({
                                  path:'/defence',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标撤三申请"){
                              this.$router.push({
                                  path:'/threeApply',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标撤三答辩"){
                              this.$router.push({
                                  path:'/threeAnswer',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode
                                  }
                                  });
                          }
     },
     updateSum(){
         if(this.$route.query.goodsName == "AI商标注册"){
                                this.$router.push({
                                    path:'/registerData',
                                    query:{
                                        click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                    }
                                    });
                            }else if(this.$route.query.goodsName == "商标变更"){
                              this.$router.push({
                                  path:'/changeData',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标续展"||this.$route.query.goodsName == "商标宽展"){
                              this.$router.push({
                                  path:'/extension',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标转让"){
                              this.$router.push({
                                  path:'/transferData',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标许可备案"){
                              this.$router.push({
                                  path:'/filingData',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "补发商标证书"){
                              this.$router.push({
                                  path:'/resendData',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标驳回复审"){
                              this.$router.push({
                                  path:'/recheck',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标异议申请"){
                              this.$router.push({
                                  path:'/objectData',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标异议答辩"){
                              this.$router.push({
                                  path:'/defence',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标撤三申请"){
                              this.$router.push({
                                  path:'/threeApply',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                  }
                                  });
                          }else if(this.$route.query.goodsName == "商标撤三答辩"){
                              this.$router.push({
                                  path:'/threeAnswer',
                                  query:{
                                      click_type:this.$route.query.goodsName,
                                        order_id:this.$route.query.orderCode,
                                        edit:1
                                  }
                                  });
                          }
     }
  }
}

</script>

<style scoped>
   
</style>
